import React from 'react';
import { HashRouter, Route } from 'react-router-dom';
import TrackerScreen from './TrackerScreen';
import HelpScreen from './HelpScreen';
import './App.css';

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Route
          exact
          path="/"
          render={props => <HelpScreen />}
        />
        <Route
          path="/:token"
          render={props => <TrackerScreen {...props} />}
        />
      </HashRouter>
    </div>
  );
}

export default App;
