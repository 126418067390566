import React from 'react';
import { Marker } from 'react-google-maps';

import camion from './camion.png';

const CamionMarker = ({
  position,
  children,
}) => (
  <Marker
    position={position}
    onClick={() => {}}
    icon={{ url: camion, labelOrigin: { x: 22, y: -10 } }}
  >
    {children}
  </Marker>
);


export default CamionMarker;
