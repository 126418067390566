import React from 'react';
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';

import UserMarker from './UserMarker';
import CamionMarker from './CamionMarker';

const options = {
  disableDefaultUI: true,
  styles: [
    {
      featureType: "poi.attraction",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "poi.business",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "poi.government",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "poi.medical",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "poi.park",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "poi.place_of_worship",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "poi.school",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "poi.sports_complex",
      stylers: [{ visibility: "off" }]
    },
  ]
};

const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      ref={props.onMapMounted}
      defaultZoom={12}
      defaultCenter={{
        lat: props.unidad.latitud,
        lng: props.unidad.longitud,
      }}
      options={options}
    >
      <CamionMarker
        position={{
          lat: props.unidad.latitud,
          lng: props.unidad.longitud,
        }}
      />
      <UserMarker usuario={props.usuario} />
    </GoogleMap>
  )),
);

export default Map;