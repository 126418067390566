import React from 'react';

import timeUp from './time-up.svg';
import './App.css';

const HelpScreen = () => (
  <div className="help-screen">
    <img src={timeUp} alt=""/>
    <h2>¡Se acabó el tiempo!</h2>
    <p>El enlace que te han compartido ha expirado.</p>
  </div>
);

export default HelpScreen;
