import React from 'react';
import { Marker } from 'react-google-maps';

import markerMe from './marker-me-small.png';

const UserMarker = ({
    usuario,
}) => (
        <Marker
            onClick={() => {}}
            icon={{ url: markerMe, anchor: { x: 11, y: 5 } }}
            position={usuario}
        >
        </Marker>
    );

export default UserMarker;
