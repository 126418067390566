import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import request from 'request-promise';

import Map from './Map';

import SECRETS from './secrets';
import './App.css';

class TrackerScreen extends Component {
  state = {
    unidad: {
      latitud: 19.70078,
      longitud: -101.18443,
      timer: 0,
    },
    refMap: {},
    usuario: {
      lat: 19.70078,
      lng: -101.18443,
    },
    interval: null,
    expired: false,
  };

  getCoords = () => {
    console.log('getCoords');
    const { token } = this.props.match.params;
    console.log(token);
    if (!token) return;
    request({
      method: 'POST',
      uri: `${SECRETS.serverurl}/track/`,
      body: { token },
      json: true, // Automatically stringifies the body to JSON
    })
      .then((resp) => {
        console.log(resp);
        const unidad = resp;
        this.setState({ unidad });
      })
      .catch((err) => {
        // POST failed...
        console.error(err);
        this.setState({ expired: true });
      });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => this.setState({
          usuario: { lat: position.coords.latitude, lng: position.coords.longitude },
        }),
      );
    }
  };

  componentWillMount = () => {
    console.log('componentWillMount');
    this.getCoords();
    const interval = setInterval(this.getCoords, 60000); // every minute
    this.setState({ interval });
  };

  componentWillUnmount = () => {
    const { interval } = this.state;
    clearInterval(interval);
  };

  onMapMounted = ref => this.setState({ refMap: ref });

  render = () => {
    const { unidad, usuario, refMap, expired } = this.state;
    const exp = new Date(unidad.timer);
    const now = new Date();
    let diff = (exp - now) / 1000;
    if (diff < 0) {
      diff = 0;
    }
    const horas = Math.floor(diff / 60 / 60);
    const mins = Math.floor(((diff / 60 / 60) - horas) * 60);
    if (expired) return <Redirect to="/" />;
    return (
      <div className="map">
        <Map
          onMapMounted={this.onMapMounted}
          loadingElement={<div style={{ height: '100%' }} />}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${SECRETS.MAPSAPIKEY}`}
          containerElement={<div style={{ height: '100%', width: '100%' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          refMap={refMap}
          unidad={unidad}
          usuario={usuario}
        />
        <div className="timebox">
          <span>{`El enlace expira en: ${horas}h ${mins}m`}</span>
        </div>
      </div>
    );
  };
}

export default TrackerScreen;
